import { TCallAssistEstimateResponse } from "src/data/callAssist/types/callAssistTypes"
import { formatPrice } from "src/utils/formatPrice"

export function getFormattedCallAssistCost(props: TCallAssistEstimateResponse) {
  const {
    billing_period: billingPeriod,
    currency_code: currencyCode,
    unit_cost: unitCostCents = 0,
    renewal_cost: renewalCostCents = 0,
    due_today: billedNow = 0,
  } = props

  const billingIsYearly =
    billingPeriod === "year" || billingPeriod === undefined

  const unitCost = Math.round(unitCostCents / 100)
  const unitCostMonthly = billingIsYearly ? Math.round(unitCost / 12) : unitCost
  const unitCostYearly = billingIsYearly ? unitCost : unitCost * 12

  const renewalCost = Math.round(renewalCostCents / 100)
  const renewalCostMonthly = billingIsYearly
    ? Math.round(renewalCost / 12)
    : renewalCost
  const renewalCostYearly = billingIsYearly ? renewalCost : renewalCost * 12

  const billedNowCost = billedNow / 100

  const unitPriceMonthly = formatPrice(unitCostMonthly, currencyCode)
  const unitPriceYearly = formatPrice(unitCostYearly, currencyCode)
  const totalPriceMonthly = formatPrice(renewalCostMonthly, currencyCode)
  const totalPriceYearly = formatPrice(renewalCostYearly, currencyCode)
  const priceBilledNow = formatPrice(billedNowCost, currencyCode)

  return {
    unitPriceMonthly,
    unitPriceYearly,
    priceBilledNow,
    totalPriceMonthly,
    totalPriceYearly,
  }
}
